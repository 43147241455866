
@import "custom-variables/variables";
@import "bootstrap";
@import "plugins/font-face";
@import "plugins/slick";
@import "plugins/icomoon";

.menu{
    li{
        a{
            color: $white;
            background-color: transparent;
            transition: all 0.3s ease-in-out;
            &::before{
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: $white;
                top: 0;
                left: 0;
                transform: scale(0.8);
                opacity: 0;
                visibility: hidden;
                border-radius: $rounded-pill;
                transition: all 0.3s ease-in-out;
            }
            i{
                opacity: 0;
                visibility: hidden;
                left: -15px;
                color: $primary;
                transition: all 0.5s ease-in-out;
            }
            &:hover, &.active, &:focus{
                // background-color: $white;
                color: $primary;
                &::before{
                    transform: scale(1);
                    opacity: 1;
                    visibility: visible;
                }
                i{
                    opacity: 1;
                    visibility: visible;
                    left: 0px;
                }
            }
        }
    }
}

.left-block, .right-block, .collections{
    height: 100vh;
}

.left-block{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.cursor-pointer {
    cursor: pointer;
}

.img-cover {
    object-fit: cover;
}

.bg-image{
    height: 100vh;
}

.slick-track .slick-slide {
    div{
        float: left;
        width: 100%;
    }
}

.top-0 {
    top: 0;
}

.left-0 {
    html[dir='ltr'] & {
        left: 0;
    }

    html[dir='rtl'] & {
        right: 0;
    }
}

.right-0 {
    html[dir='ltr'] & {
        right: 0;
    }

    html[dir='rtl'] & {
        left: 0;
    }
}

.bottom-0 {
    bottom: 0;
}

.border-width-2{
    border-width: 2px !important
}
  
  
@each $name, $color in $grays {
    .text-gray-#{$name} {
        color: $color;
    }

    .bg-gray-#{$name} {
        background-color: $color;
    }
}
  
$fontsize: (
    'xxxs': 10px,
    'xxs': 11px,
    'xs': 12px,
    'sm': 13px,
    'normal': 14px,
    'md': 15px,
    'base': 16px,
    'lg': 18px,
    'xl': rem(20),
    'xxl': rem(22),
);
  
@each $name, $size in $fontsize {
    .font-#{$name} {
        font-size: $size !important;
    }
}

.banner-content{
    left: 55%;
    max-width: 400px;
}

.font-weight-medium {
    font-weight: $font-weight-medium;
}

.font-weight-semibold {
    font-weight: $font-weight-semibold;
}

.gutter {
    &-12 {
      margin-left: -12px !important;
      margin-right: -12px !important;
  
      & > [class^='col'] {
        padding-left: 12px !important;
        padding-right: 12px !important;
      }
    }
}

@keyframes fadeInUpSD {
    0% {
      opacity: 0;
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
}

.slick-current .animate {
    animation-name: fadeInUpSD;
    animation-duration: 1s;
    opacity: 0;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}

.animate{
    opacity: 0;
}

@for $var from 1 to 4 {
    .slick-current .animate:nth-child(#{$var}) {
        animation-duration: #{$var - 0.25}s;
    }
}

.gold{
    background-color: #FFFAF0;
    background-image: url('../images/pattern-gold.png');
    background-repeat: repeat;
}

.diamond{
    background-color: #131313;
    background-image: url('../images/pattern-diamond.png');
}

.card{
    figure{
        height: 217px;
        img{
            max-height: 100%;
        }
    }
}

.slideUp{
    transform: translateY(60%);
    animation-name: slideUp;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes slideUp {
    from{
        transform: translateY(60%);
    }
    to{
        transform: translateY(0%);
    }
}

@for $var from 2 to 7 {
    .collectionbox:nth-child(#{$var}) .slideUp{
        animation-delay: #{$var / 10 - 0.1}s;
    }
}

.collection-logo{
    bottom: 80%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    margin-bottom: 15px;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-delay: 1.6s;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from{
        opacity: 0;
        margin-bottom: 15px;
    }
    to{
        opacity: 1;
        margin-bottom: 0px;
    }
}

@for $var from 2 to 7 {
    .collectionbox:nth-child(#{$var}) .collection-logo, .collectionbox:nth-child(#{$var}) a{
        animation-delay: #{$var / 10 + 1.5}s;
    }
}

.collectionbox{
    &::after{
        content: "";
        width: 100%;
        height: 20%;
        bottom: 0;
        left: 0;
        position: absolute;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.58) 100%);
    }
    .explore{
        z-index: 1;
        opacity: 0;
        bottom: -30px;
        animation-name: fadeUp;
        animation-duration: 1s;
        animation-delay: 1.6s;
        animation-fill-mode: forwards;
    }
}

@keyframes fadeUp {
    from{
        opacity: 0;
        bottom: -30px;
    }
    to{
        opacity: 1;
        bottom: 0px;
    }
}

.zIndex-1{
    z-index: 1;
}

.css-1s2u09g-control, .css-1pahdxg-control{
    min-height: 48px !important;
    border-color: $border-color !important;
}

.left-banner{
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.74);
    line-height: 24px;
    &::before{
        content: "";
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, rgba(0,0,0,0.75), rgba(0,0,0,0.65), transparent);
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

.toTop{
    width: 50px;
    height: 50px;
    i{
        transform: rotate(90deg);
    }
}

.right-block{
    scroll-behavior: smooth;
}