@import '../../../assets/sass/custom-variables/variables';

.Loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__text {
    color: $gray-900;
    margin-top: rem(10);
  }
}

.ContainerLoader {
  &__icon {
    img {
      width: 50px;
      border-radius: 50%;
    }
  }
}
