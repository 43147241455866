$bootstrap-sass-asset-helper: false !default;
$browser-context: 16; // Default
@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

$primary: #9A0056;
$secondary: #161616;
$dark: #000000;

$h1-font-size: rem(36);
$h2-font-size: rem(24);
$h3-font-size: rem(20);
$h4-font-size: rem(18);
$h5-font-size: rem(16);
$h6-font-size: rem(15);

$font-family-sans-serif: 'Poppins', sans-serif;
$font-family-base: 'Poppins', sans-serif;

$font-weight-medium: 500;
$font-weight-semibold: 600;

$gray-800: #161616;
$gray-900: #020202;
// $white: #FFFFFF;

$input-btn-padding-y: 0.75rem